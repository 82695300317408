import React, { FunctionComponent } from 'react';
import { Link } from 'react-scroll';
import { TranslateFunc } from '@modules/translate/types/translate';

interface IMouseBlockProps {
  translate: TranslateFunc,
}

const MouseBlock: FunctionComponent<IMouseBlockProps> = (props) => {
  const {translate} = props

  return (
    <div className="mouse-block" data-aos="fade-zoom-in" data-aos-delay="2200">
      <Link
        activeClass="active"
        duration={0}
        to="nextBlock"
        smooth
        spy
      >
        <div className="mouse">
          <img src="/landing/svg/mouse.svg" alt="icon mouse" />
          <span>
            {translate('about.APS.MLQ')}
          </span>
        </div>
      </Link>
    </div>
  );
}

export default MouseBlock;
