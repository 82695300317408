import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { loadTrialProducts } from '@commands/products/products';
import { ProductByCategory } from '@modules/investments/products/types/product';

const useTrialProducts = () => {
  const dispatch = useDispatch();
  const [items, setItems] = useState<ProductByCategory[]>([])

  useEffect(() => {
    // @ts-ignore
    dispatch(loadTrialProducts()).then(setItems);
  }, [])

  return items;
}

export default useTrialProducts;
