import { digitFormat } from '@utils/formatDigits';
import { Subject } from 'rxjs';
import { User } from '@modules/auth/types/Users';

export interface INotifMessagePayload {
  link?: string
  userId?: number
}

export interface INotifMessage {
  message: string
  extra?: object
  payload?: INotifMessagePayload
  title?: string
  duration?: number
  onClick?: () => void
}

export interface ILoadingEvent {
  kind: string
  context: string
  size: number
}

export interface CommonReducer {
  contexts: ContextType
  error: Subject<INotifMessage>
  success: Subject<INotifMessage>
  loading: Subject<ILoadingEvent>
  onUserChanged: Subject<User>
}

export type ContextType = { [key: string]: number }

export const appCapacity = parseInt(process.env.NEXT_PUBLIC_CAPACITY || '2', 10);
export const appCapacityV = Math.pow(10, appCapacity);

declare global {
  interface Number {
    noExponents: () => string;
  }
}


Number.prototype.noExponents = function () {
  const data = String(this).split(/[eE]/);
  if (data.length == 1) return data[0];

  let z = '', sign = this < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
};

export class Price {
  private _value: number;
  private readonly _capacity: number;
  private readonly _capacityFixed: number;

  constructor(value: number, capacity: number = appCapacity, capacityFixed: number = 0) {
    this._value = value;
    this._capacity = capacity;
    this._capacityFixed = capacityFixed || appCapacity;
  }

  get value(): number {
    const value = this._value / Math.pow(10, this._capacity);

    if (!this._capacityFixed) {
      return Math.floor(value);
    }

    return parseFloat(value.toFixed(this._capacity));
  }

  get formattedValue(): string {
    return digitFormat(this.value).toString();
  }

  set value(value: number) {
    this._value = value;
  }

  toString: () => string = () => parseFloat(this.value.toFixed(this._capacityFixed)).noExponents();
}

export default interface FormSubmitProps<T, P> {
  onSubmitForm: (data: T) => P,
}

export interface IReactSelectChange<T, P> {
  value: T,
  label: P
}
