import { AppStorageType } from '@app-types/appStorageType';
import useTrialProducts from '@hooks/useTrialProducts';
import useRate from '@modules/deposit/useRate/index';
import { FC } from 'react';
import { useSelector } from 'react-redux';

interface TrialLessonPriceLabelProps {
  extraClass?: string
  prefix?: string
}

const TrialLessonPriceLabel: FC<TrialLessonPriceLabelProps> = (props) => {
  const items = useTrialProducts();
  const [getConvertAmount] = useRate();
  const country = useSelector<AppStorageType, string>(state => state.country.country);

  if (!items) {
    return null;
  }

  if (items.length === 0) {
    return null;
  }

  const item = items[0];

  if (!item.products || !item.products.length) {
    return null;
  }

  const stage = item.products[0].getFirstStageForCountry(country || 'en');

  if (!stage) {
    return null;
  }

  return (
    <div className={`trial-lesson-price-label ${props.extraClass || ''}`}>
      {`${props.prefix ? props.prefix + ' ' : ''}`} {getConvertAmount(stage.getPrice(), stage.token)}
    </div>
  )
}

export default TrialLessonPriceLabel;