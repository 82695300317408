import { AppDispatch, AppStorageType } from '@app-types/appStorageType';
import { apiCall } from '@commands/httpClient';
import { ResultListRes } from '@modules/apiClient/type';
import { IProductByCategory, ProductByCategory, StageProduct } from '@modules/investments/products/types/product';
import { StageLesson } from '@modules/investments/products/types/stage';
import { setProductsListLesson } from '@modules/investments/products/actions';
import { BaseCommandProps } from '@app-types/CommonTypes';
import { setLessonStatisticProduct } from '@modules/schedule/actions';

export const loadTrialProducts = () => (_: AppDispatch<object>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const country = state.country.country;
  return _(apiCall<object, ResultListRes<IProductByCategory[]>>(`/v1/showcase/list`, { slug: 'trial', country, hideRemoved: false }, { method: 'post' }))
    .then(({ list }) => {
      return list.map(item => {
        const products = item.products.map(p => {
          const stages = p.stages.map(s => new StageLesson(s));
          if (!stages.length) {
            return;
          }
          return new StageProduct<StageLesson>(p, stages);
        }).filter(i => !!i);
        return new ProductByCategory({ ...item, products });
      });
    });
};

export const loadProducts = (slug: string, hideRemoved: false) => (_: AppDispatch<object>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const country = state.country.country;
  return _(apiCall<object, ResultListRes<IProductByCategory[]>>(`/v1/showcase/list`, { slug, country, hideRemoved }, { method: 'post' }))
    .then(({ list }) => {
      const lessons = list.map(item => {
        const products = item.products.map(p => {
          const stages = p.stages.map(s => new StageLesson(s));
          if (!stages.length) {
            return;
          }
          return new StageProduct<StageLesson>(p, stages);
        }).filter(i => !!i);
        return new ProductByCategory({ ...item, products });
      });
      _(setProductsListLesson(lessons));
    });
};

export const loadProductsByTeacherId = (teacherId: number, subjectId?: number) => (_: AppDispatch<object>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const country = state.country.country;
  return _(apiCall<object, ResultListRes<IProductByCategory[]>>(`/v1/showcase/list/by-tid`, { teacherId, country, subjectId }, { method: 'post' }))
    .then(({ list }) => {
      const lessons = list.map(item => {
        const products = item.products.map(p => {
          const stages = p.stages.map(s => new StageLesson(s));
          if (!stages.length) {
            return;
          }
          return new StageProduct<StageLesson>(p, stages);
        }).filter(i => !!i);
        return new ProductByCategory({ ...item, products });
      });
      _(setProductsListLesson(lessons));
    });
};

interface StageProductResp extends BaseCommandProps {
  product: StageProduct<StageLesson>;
}

export const loadProductByStage = (stageId: number) => (_: AppDispatch<object>) => {
  _(apiCall<object, StageProductResp>(`/v1/stage/get-product/${stageId}`, undefined, { method: 'get' }))
    .then((res) => {
      if (!res) {
        return;
      }

      const { product } = res;
      _(setLessonStatisticProduct(product));
    });
};
