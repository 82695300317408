import { ICurrency } from '@modules/deposit/types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStorageType } from '@app-types/appStorageType';
import { getRate } from '@commands/deposit';
import { handlers } from '@modules/deposit/useRate/handlers';
import { Price } from '@modules/common/types';
import get from 'lodash/get'

interface UseRateProps {
  getConvertAmount: IGetRate;
}

export type IGetRate = (price: number, token?: string) => number

const useRate = (): [IGetRate] => {
  const dispatch = useDispatch();

  const getRateAction = (slug: string) => dispatch(getRate(slug));

  const methods = useSelector<AppStorageType, UseRateProps>(state => {
    const currency = get(state, 'country.current.currency', 'usd')
    const rate = state.deposit.currency;
    const handler = handlers[currency];
    if (!handler) {
      const getConvertAmount = (price: number, token?: string) => new Price(price, undefined, 2).formattedValue;
      return { getConvertAmount };
    }

    const action = handler(rate, getRateAction);

    const getConvertAmount = (price: number, token?: string) => {
      let h = action;
      if (token) {
        const t = token?.toLowerCase();
        if (handlers[t]) {
          h = handlers[t]({} as ICurrency, getRate);
        }
      }

      if (!price) {
        return h(0, token);
      }
      return h(price, token);
    };

    return { getConvertAmount };
  });


  return [methods.getConvertAmount];
};


export default useRate;
