import { AppDispatch, AppStorageType } from '@app-types/appStorageType';
import { apiCall } from '@commands/httpClient';
import { setCurrency } from '@modules/deposit/actions';
import { CurrencyRes, ICurrency } from '@modules/deposit/types';

export const getRate = (current: string = 'usd') => (dispatch: AppDispatch<CurrencyRes>, getState: () => AppStorageType) => {
  const state: AppStorageType = getState();
  const currencyObj = state.deposit.currency;
  if (!currencyObj[current as keyof ICurrency]) {
    dispatch(apiCall<object, CurrencyRes>(`/v1/currency/${current}`, undefined, { method: 'get' }))
      .then((res: CurrencyRes) => dispatch(setCurrency({ slug: current, value: res.list })));
  }
};
