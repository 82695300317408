import { Price } from '@modules/common/types';
import { ICurrency } from '@modules/deposit/types';

const eur = (rate: ICurrency, getRate: (slug: string) => void) => (price: number, token: string) => {
  getRate('eur');
  const currency = rate['eur' as keyof ICurrency];
  return `€${new Price(price * currency, undefined, 2).formattedValue}`;
};

export default eur;
