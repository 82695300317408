import { Price } from '@modules/common/types';
import { ICurrency } from '@modules/deposit/types';

const gbp = (rate: ICurrency, getRate: (slug: string) => void) => (price: number, token: string) => {
  getRate('gbp');
  getRate('usd');
  const currencyGBP = rate['gbp' as keyof ICurrency];
  const currencyUSD = rate['usd' as keyof ICurrency];
  return `£${new Price((currencyUSD/currencyGBP*price), undefined, 2).formattedValue}`;
};

export default gbp;
