import rub from '@modules/deposit/useRate/handlers/rub';
import { ICurrency } from '@modules/deposit/types';
import rup from '@modules/deposit/useRate/handlers/rup';
import usd from '@modules/deposit/useRate/handlers/usd';
import eur from '@modules/deposit/useRate/handlers/eur';
import gbp from '@modules/deposit/useRate/handlers/gbp';

export type HandlersConvert = { [key: string]: (rate: ICurrency, getRate: (slug: string) => void) => (price: number, token?: string) => string }

export const handlers: HandlersConvert = {
  rub,
  rup,
  usd,
  eur,
  gbp,
};
