export const digitFormat = (val: string | number) => {
  //TODO: js => ts
  if (!val)
    return val;

  if (typeof val != 'string')
    val = val.toString();
  return val.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
};

export const digitRoundFormat = (val: number) => {
  return digitFormat(Math.round(val));
};
