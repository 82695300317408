import TrialLessonPriceLabel from '@components/TrialLessonPriceLabel';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { parseCookies } from 'nookies';
import { Controller, Scene } from 'react-scrollmagic';
import dynamic from 'next/dynamic';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import LandingLayout from '@layouts/LandingLayout';
import Button from '@components/Button';
import useLocale from '@modules/translate/useLocale';
import { setCookie } from '@modules/common/tools/cookie';
import MouseBlock from '@components/MosueBlock';
import JsonData from '@components/JsonData';
import useSession from '@hooks/useSessions';
import verticalCardSchema from '@schemas/landing/main/landing-cards/vertical/data-schema.json';
import verticalCardUiSchema from '@schemas/landing/main/landing-cards/vertical/ui-schema.json';
import { VerticalLandingCardsProps } from '@schemas/landing/main/landing-cards/vertical/types';

const SwiperReviews = dynamic(() => import('@components/SwiperReviews'), { ssr: false });
const VideoTeachers = dynamic(() => import('@components/ReviewsTeachers'), { ssr: false });
const ScrollHorizontalSlider = dynamic(() => import('@components/ScrollHorizontalSlider'), { ssr: false });
const StudyingProcess = dynamic(() => import('@components/StudyingProccess'), { ssr: false });
const RegisterLandingComponent = dynamic(() => import('@components/Landing/RegisterLandingComponent'), { ssr: false });

interface IShellVerticalSlide {
  href?: string,
  index: number,
  length: number,
}

const ShellVerticalSlide: FunctionComponent<IShellVerticalSlide> = ({ href, index, length, children }) => {
  const className = index !== length && `popcards__item popcards__item_${index}` || undefined;
  if (href) {
    return (
      <a style={{ display: 'block', opacity: 'initial' }} href={href} className={className}>
        {children}
      </a>
    );
  } else {
    return (
      <div className={className}>
        {children}
      </div>
    );
  }
};

const Main: FunctionComponent = () => {
  const [translate] = useLocale();
  const [cookieVisible, setCookieVisible] = useState(false);
  const { user } = useSession();

  useEffect(() => {
    const { cookie_modal } = parseCookies();
    setCookieVisible(!parseInt(cookie_modal));
  }, []);


  const onAceptCookie = useCallback(() => {
    setCookie('cookie_modal', '1');
    setCookieVisible(false);
  }, []);

  return (
    <LandingLayout>
      <section role="main">
        <div className="section section_main section_main-page">
          <div className="grid grid_l-r">
            <div className="grid__main-l">
              <h1 className="title-block title-block_main">
                <div className="title-block__row-animated title-block__row-animated_main">
                  <span data-aos="fade-up" data-aos-delay="1000">
                    {translate('main.HQI.AGE')}
                  </span>
                </div>
                <div className="title-block__row-animated title-block__row-animated_main">
                  <span className="color-blue" data-aos="fade-up" data-aos-delay="1100">
                    {translate('main.HQI.GQW')}
                  </span>
                </div>
                <div className="title-block__row-animated title-block__row-animated_main">
                  <span data-aos="fade-up" data-aos-delay="1200">
                    {translate('main.HQI.MLQ')}
                  </span>
                </div>
              </h1>
              <div className="text-block text-block_main aos-init aos-animate">
                <p data-aos="fade-up" data-aos-delay="1500">
                  {translate('main.HQI.OQQ')}
                </p>
              </div>
              <div className="buttons-block" data-aos="fade-up" data-aos-delay="1800">
                <Button link="/auth/sign-in" className="btn_black btn_main">
                  {translate('main.HQI.AGL')}
                </Button>
                <div className="buttons-lable" data-aos="fade-left" data-aos-delay="2000">
                  <div className="lable">
                    <div className="label-translate">
                      <p>{translate('label.card')}</p>
                      <img src="/landing/svg/lable-1.svg" alt="lable card icon" />
                    </div>
                    <TrialLessonPriceLabel />
                  </div>
                </div>
              </div>
              <MouseBlock translate={translate} />
            </div>
            <div className="grid__main-r">
              <div className="wide-right-block">
                <div className="mask-pic mask-pic_main-1" data-aos="fade-up" data-aos-delay="500">
                  <div className="mask-pic-cont">
                    <img src="/landing/img/mask-pic_main-1.jpg" alt="mask-pic_main-1 image" />
                  </div>
                </div>
                <div className="mask-pic mask-pic_main-2" data-aos="fade-up" data-aos-delay="500">
                  <div className="mask-pic-cont">
                    <img src="/landing/img/mask-pic_main-2.jpg" alt="mask-pic_main-2 image" />
                  </div>
                  <div className="mask-text-cont" data-aos="fade-right" data-aos-delay="1000">
                    <div className="row-info-wrap">
                      <div className="row-info">
                        <div className="row-info-text">
                          <span>
                            <strong>
                              {translate('main.HQI.BOZ')}
                            </strong>
                          </span>
                        </div>
                      </div>
                      <div className="row-info">
                        <div className="row-info-text">
                          <span>
                            <strong>
                              {translate('main.HQI.NOT')}
                            </strong>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mask-pic mask-pic_main-3" data-aos="fade-up" data-aos-delay="500">
                  <div className="mask-pic-cont">
                    <img src="/landing/img/mask-pic_main-3.jpg" alt="mask-pic_main-3 image" />
                  </div>
                  <div className="mask-text-cont" data-aos="fade-right" data-aos-delay="1000">
                    <div className="row-info-wrap">
                      <div className="row-info">
                        <div className="row-info-text">
                          <span>
                            <strong>
                              {translate('main.HQI.ROQ')}
                            </strong>
                          </span>
                        </div>
                      </div>
                      <div className="row-info">
                        <div className="row-info-text">
                          <span>
                            <strong>
                              {translate('main.HQI.QKR')}
                            </strong>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="circle-pic circle-pic_main-1" data-aos="zoom-in" data-aos-delay="0">
                  <div className="circle-pic-cont circle-pic-cont_green">
                    <div className="circle-radius">
                      <div className="loader-pacman" />
                      <div className="loader-head-wrap">
                        <div className="loader-head" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="circle-pic circle-pic_main-2" data-aos="zoom-in" data-aos-delay="0">
                  <div className="circle-pic-cont circle-pic-cont_grey">
                    <div className="circle-radius">
                      <div className="loader-pacman" />
                      <div className="loader-head-wrap">
                        <div className="loader-head" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="circle-pic circle-pic_main-3">
                  <div className="circle-pic-cont circle-pic-cont_blue" data-aos="zoom-in" data-aos-delay="0">
                    <div className="circle-radius circle-radius_mirror circle-radius_delay">
                      <div className="loader-pacman" />
                      <div className="loader-head-wrap">
                        <div className="loader-head" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="nextBlock" />
      <section>
        <div className="section section_untop" id="section_untop">
          <div className="section-untop-first">
            <div className="circle-pit">
              <div className="circle-pit__elem circle-pit__elem_main-1" data-aos="fade-left"
                   data-aos-anchor=".half-img-big">
                <img src="/landing/svg/circle-pit-1.svg" alt="circle-pit-1 icon" />
              </div>
              <div
                className="circle-pit__elem circle-pit__elem_main-2"
                data-aos="fade-left"
                data-aos-anchor=".half-img-big"
              >
                <img src="/landing/svg/circle-pit-2.svg" alt="circle-pit-2 icon" />
              </div>
              <div
                className="circle-pit__elem circle-pit__elem_main-3"
                data-aos="zoom-in"
                data-aos-anchor=".half-img-big"
              >
                <img src="/landing/svg/circle-pit-3.svg" alt="circle-pit-3 icon" />
              </div>
            </div>
            <div className="grid grid_l-r">
              <div className="grid__half-l">
                <div className="title-block">
                  <div className="title-block__row-animated">
                    <span className="color-white" data-aos="fade-up" data-aos-delay="400">
                      {translate('main.KLA.AGE')}
                    </span>
                  </div>
                </div>
                <div className="text-block" data-aos="fade-up" data-aos-delay="600">
                  <p className="color-white">
                    {translate('main.KLA.GQW')}
                  </p>
                </div>
                <div className="buttons-block" data-aos="fade-up" data-aos-delay="700">
                  <Button link="/about" className="btn_black btn_black-to-white btn_main">
                    {translate('main.KLA.MLQ')}
                  </Button>
                </div>
              </div>
              <div className="grid__half-r">
                <LazyLoadImage
                  alt="photo"
                  className="half-img-big"
                  src={String(translate('rest.MGI'))}
                  data-aos="fade-up"
                  data-aos-delay="0"
                />
              </div>
            </div>
          </div>
          <div className="section-untop-second">
            <div className="grid grid_wide custom">
              <Controller>
                <div className="sticky-wrap">
                  <div className="sticky-container">
                    <div className="circle-pit">
                      <div className="circle-pit__elem circle-pit__elem_main-4" data-aos="fade-left"
                           data-aos-anchor=".half-img-big">
                        <img src="/landing/svg/circle-pit-4.svg" alt="circle-pit-4 icon" />
                      </div>
                    </div>
                    <div className="grid title-block">
                      <div className="title-block__row-animated">
                        <span className="color-white" data-aos="fade-up">
                          {translate('main.KLA.AGL')}
                        </span>
                      </div>
                    </div>
                    <div id="sticky-anchor" className="sticky-anchor" />
                  </div>
                  <div className="wide-container-slider">
                    <div className="sticky-slider" id="sticky-slider">
                      <ScrollHorizontalSlider />
                    </div>
                  </div>
                </div>
              </Controller>
            </div>
          </div>
          <div className="circle-pit">
            <div className="circle-pit__elem circle-pit__elem_main-2" data-aos="fade-left"
                 data-aos-anchor=".half-img-big">
              <img src="/landing/svg/circle-pit-2.svg" alt="circle-pit-2 icon" />
            </div>
            <div className="circle-pit__elem circle-pit__elem_main-4" data-aos="fade-left"
                 data-aos-anchor=".half-img-big">
              <img src="/landing/svg/circle-pit-4.svg" alt="circle-pit-4 icon" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="section section_reviews">
          <div className="grid grid_wide">
            <div className="grid title-block">
              <div className="title-block__row-animated">
                <span className="color-white" data-aos="fade-up">
                  {translate('main.YTA.AGE')}
                </span>
              </div>
            </div>
            <div className="wide-container">
              <div className="swiper-container swiper-container_reviews" data-aos="fade-left">
                <SwiperReviews />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="overflow-x_hidden">
        <div className="section section_people">
          <div className="grid grid_wide">
            <div className="grid title-block title-block_centered">
              <div className="title-block__row-animated">
                <span className="title-big" data-aos="fade-up">
                  {translate('main.ZVK.AGE')} <span className="color-blue title-big">{translate('main.ZVK.GQW')}</span>
                  <br />
                  {translate('main.ZVK.MLQ')}
                </span>
              </div>
            </div>
            <div className="grid">
              <VideoTeachers />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="section section_funtions">
          <div className="grid">
            <div className="functions-list">
              <StudyingProcess />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="section section_popcards">
          <div className="grid">
            <JsonData<VerticalLandingCardsProps>
              context="landing_vertical_cards"
              schema={verticalCardSchema}
              uiSchema={verticalCardUiSchema}
              editable={user?.isAdmin || false}
            >
              {(value) => {
                return <Controller>
                  <div className="popcards">
                    {value.cards.map((i, index) => {
                      return (
                        <Scene
                          classToggle={index !== value.cards.length && [`.popcards__item_${index}`, 'popcards__item_scale'] || undefined}>
                          <ShellVerticalSlide href={i.href} index={index} length={value.cards.length}>
                            <div className="popcard" id={index !== 1 && `popcard-${index}` || undefined}
                                 style={{ backgroundColor: i.color }}>
                              <div className="popcard-cont">
                                <div className="popcard__info">
                                  <div className="popcard-info__title">
                                    {translate(i.title)}
                                  </div>
                                  <div className="popcard-info__text">
                                    <p>
                                      {translate(i.content)}
                                    </p>
                                  </div>
                                  <div className="popcard-info__button">
                                    <Button link="/auth/sign-in" className="btn_white">
                                      {translate('main.MQR.AGE')}
                                    </Button>
                                  </div>
                                </div>
                                <div className="popcard__img">
                                  <LazyLoadImage
                                    alt="picture"
                                    src={i.image}
                                  />
                                </div>
                                <div className="popcard__nums">
                                  {index + 1}/{value.cards.length}
                                </div>
                              </div>
                            </div>
                            {index === 1 && <div id={`popcards-anchor-${index}`} />}
                          </ShellVerticalSlide>
                        </Scene>
                      );
                    })}
                  </div>
                </Controller>;
              }}
            </JsonData>
          </div>
        </div>
      </section>
      <section>
        <div className="section section_funtions">
          <div className="grid">
            <div className="functions-list">
              <div className="function">
                <div className="function-bg">
                  <div className="function-bg__item function-bg__item_main-1" data-aos="zoom-in" data-aos-delay="500">
                    <img src="/landing/svg/function-bg-1.svg" alt="function-bg-1 icon" />
                  </div>
                  <div className="function-bg__item function-bg__item_main-2" data-aos="fade-up">
                    <div className="circle-pic-cont">
                      <img src="/landing/svg/function-bg-2.svg" alt="function-bg-2 icon" />
                    </div>
                  </div>
                </div>
                <div className="function-cont">
                  <div className="function__img" data-aos="fade-up" data-aos-delay="500">
                    <LazyLoadImage
                      alt="photo"
                      src={String(translate('rest.MGP'))}
                    />
                  </div>
                  <div className="function__info">
                    <div className="function-info__title" data-aos="fade-up" data-aos-delay="800">
                      <h3>
                        {translate('main.LJZ.MLQ')}
                      </h3>
                    </div>
                    <div className="function-info__text" data-aos="fade-up" data-aos-delay="900">
                      <p>
                        {translate('main.LJZ.AGL')}
                      </p>
                    </div>
                    <div className="buttons-block" data-aos="fade-up" data-aos-delay="1000">
                      <Button link="/auth/sign-in" className="btn_black btn_capitalize">
                        {translate('main.LJZ.AGE')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="function">
                <div className="function-cont">
                  <div className="function__info">
                    <div className="function-info__title" data-aos="fade-up" data-aos-delay="800">
                      <h3>
                        {translate('main.LJZ.BOZ')}
                      </h3>
                    </div>
                    <div className="function-info__text" data-aos="fade-up" data-aos-delay="900">
                      <p>
                        {translate('main.LJZ.NOT')}
                      </p>
                    </div>
                    <div className="buttons-block" data-aos="fade-up" data-aos-delay="1000">
                      <Button link="/auth/sign-in" className="btn_black btn_capitalize">
                        {translate('main.LJZ.GQW')}
                      </Button>
                    </div>
                  </div>
                  <div className="function__img" data-aos="fade-up" data-aos-delay="500">
                    <LazyLoadImage
                      alt="photo"
                      src={String(translate('rest.BPZ'))}
                    />
                  </div>
                  <div className="function-bg">
                    <div className="function-bg__item function-bg__item_main-3" data-aos="fade-right">
                      <div className="circle-pic-cont circle-pic-cont_blue">
                        <div className="circle-radius">
                          <div className="loader-pacman" />
                          <div className="loader-head-wrap">
                            <div className="loader-head" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="function-bg__item function-bg__item_main-4" data-aos="fade-left">
                      <div className="circle-pic-cont circle-pic-cont_green">
                        <div className="circle-radius circle-radius_mirror circle-radius_delay">
                          <div className="loader-pacman" />
                          <div className="loader-head-wrap">
                            <div className="loader-head" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="function">
                <div className="function-bg">
                  <div className="function-bg__item function-bg__item_main-1" data-aos="zoom-in" data-aos-delay="500">
                    <img src="/landing/svg/function-bg-1.svg" alt="function-bg-1 icon" />
                  </div>
                  <div className="function-bg__item function-bg__item_main-2" data-aos="fade-up">
                    <div className="circle-pic-cont">
                      <img src="/landing/svg/function-bg-2.svg" alt="function-bg-2 icon" />
                    </div>
                  </div>
                </div>
                <div className="function-cont">
                  <div className="function__img" data-aos="fade-up" data-aos-delay="500">
                    <LazyLoadImage
                      alt="photo"
                      src={String(translate('rest.QQL'))}
                    />
                  </div>
                  <div className="function__info">
                    <div className="function-info__title" data-aos="fade-up" data-aos-delay="800">
                      <h3>
                        {translate('main.LJZ.ROQ')}
                      </h3>
                    </div>
                    <div className="function-info__text" data-aos="fade-up" data-aos-delay="900">
                      <p>
                        {translate('main.LJZ.QKR')}
                      </p>
                    </div>
                    <div className="buttons-block" data-aos="fade-up" data-aos-delay="1000">
                      <Button link="/auth/sign-in" className="btn_black btn_capitalize">
                        {translate('main.LJZ.AGE')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RegisterLandingComponent
        title={[{ text: 'register-block.title.KGG.LAR', blue: true }, { text: 'register-block.title.KGG.TZA' }]}
        text={['register-block.description.EHJ']}
        img="register-block.tablet.QKL"
        button={{ text: 'register-block.button.YFG', class: 'btn_blue' }}
        info
      />
      <section>
        <div className={`cookie ${cookieVisible ? 'show' : ''}`}>
          <div className="cookie-close button_cookie" onClick={() => onAceptCookie()} />
          <div className="cookie-cont">
            <div className="cookie-cont__img">
              <LazyLoadImage
                alt="cookie icon"
                src="/landing/svg/cookie.svg"
              />
            </div>
            <div className="cookie-cont__info">
              <div className="cookie-title">
                Cookie
              </div>
              <div className="cookie-text">
                {translate('main.cookie.text')}
              </div>
              <div className="cookie-butons">
                <Button onClick={() => onAceptCookie()} className="btn_black btn_header">
                  ОК
                </Button>
                <Button
                  link="/pdf/privacy-policy.pdf"
                  className="btn_blue-transp btn_header btn_no-wrap"
                >
                  {translate('common.more-info')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LandingLayout>
  );
};

export default trackWindowScroll(Main);
