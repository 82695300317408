import { AppDispatch, AppStorageType } from '@app-types/appStorageType';
import { apiCall } from '@commands/httpClient';
import { IList, ListContextArrayResp, ListUpdateContextResp } from '@modules/lists/types';
import { setListValueAction } from '@modules/lists/actions';
import { debounce } from 'lodash';
import forEach from 'lodash/forEach';

let listLoadContexts: string[] = [];

const _loadContextList = debounce((dispatch: AppDispatch<object>) => {
  if (!listLoadContexts.length) return;

  return dispatch(apiCall<object, ListContextArrayResp>(`/v1/list/list-by-contexts`, {contexts: listLoadContexts}, { method: 'post' }))
    .then(({ list }) => {
      forEach(list, ({ value, context }) => {
        value && dispatch(setListValueAction({ context, value: JSON.parse(value) } as IList))
      })

      listLoadContexts = [];
    })
}, 500);

export const loadListByContext = (context: string) => (dispatch: AppDispatch<object>, getState: () => AppStorageType) => {
  const state = getState();
  if (state.list.items[context]) {
    return;
  }

  listLoadContexts.push(context);

  return _loadContextList(dispatch);
};

export const saveListWithContext = (context: string, value: object) => (dispatch: AppDispatch<object>) => {
  return dispatch(apiCall<object, ListUpdateContextResp>(`/v1/list/edit/${context}`, { value }, { method: 'post' }))
    .then(({ context, value }) => dispatch(setListValueAction({ context, value } as IList)));
};
