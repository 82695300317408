import { ReactChild, ReactChildren } from 'react';
import useList from '@hooks/useList';
import { IListEditData } from '@modules/lists/types';

interface JsonDataProps<T> {
  context: string,
  schema: object,
  customClass?: string
  uiSchema?: object,
  editable: boolean,
  children: (items: T) => ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

const JsonData = <T extends object>(props: JsonDataProps<T>) => {
  const [data, edit] = useList<T>(props.context);

  if (props.editable) {
    return (
      <>
        <div
          className={`list-ms_edit-cont ${props.customClass ?? ''}`}
          onClick={() => edit.next({
            context: props.context,
            jsonSchema: props.schema,
            uiSchema: props.uiSchema,
            data,
          } as IListEditData)}
        >
          Edit
        </div>
        {data && props.children(data)}
      </>
    );
  }

  return data && props.children(data) || null;
};

export default JsonData;
