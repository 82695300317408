import { Subject } from 'rxjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppStorageType } from '@app-types/appStorageType';
import { IListEditData } from '@modules/lists/types';
import { useEffect } from 'react';
import { loadListByContext } from '@modules/lists/commands';

const useList = <T extends object>(context: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    context && dispatch(loadListByContext(context));
  }, [context])

  return useSelector<AppStorageType, [T | undefined, Subject<IListEditData>]>(state => {
    const item = state.list.items[context]

    if (item) {
      return [item as T, state.list.editSubject];
    }

    return [undefined, state.list.editSubject];
  })
}

export default useList;
